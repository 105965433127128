<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <!--<breadcrumb :title="title" :path="path" :text="text" :text1="text1" :search_data="searchform" :columns="originalColumn" /> -->
		
		<reports-header :title="title" :path="path" :text="text" :text1="text1" :search_data="searchform" :columns="originalColumn" />
		
        <!-- /Page Header -->
        <!-- Search Filter -->
       <!-- <attendance-reports-filter /> -->
		<Form @submit="onSearch">
		<div class="row filter-row">
			<div class="col-sm-6 col-md-3">
			  <div class="input-block mb-3 form-focus focused">
				<vue-select
				  :options="employee_list"
				  id="projectreportname"
				  v-model="searchform.s_employee_id"
				  placeholder="-"
				/>

				<label class="focus-label">Employee Name</label>
			  </div>
			</div>
			<div class="col-sm-6 col-md-3">
			  <div class="input-block mb-3 form-focus select-focus">
				<vue-select :options="Year" id="year" placeholder="-" v-model="searchform.year" />
				<label class="focus-label">Select Year</label>
			  </div>
			</div>
			<div class="col-sm-6 col-md-2">
			  <div class="input-block mb-3 form-focus select-focus">
				<vue-select :options="Month" id="month" placeholder="-" v-model="searchform.month" />
				<label class="focus-label">Select Month</label>
			  </div>
			</div>
			<div class="col-sm-6 col-md-2">
			  <div class="d-grid">
				<button class="btn btn-success w-100 submit-btn">Search</button>
			  </div>
			</div>
			<div class="col-sm-6 col-md-2">
			  <div class="d-grid">
				<a href="javascript:void(0)" class="btn btn-danger w-100 submit-btn" @click="clearForm">Clear</a>
			  </div>
			</div>
		</div>
		</Form>
  
        <!-- /Search Filter -->
        <div class="row">
          <div class="col-md-12">
		  <!--
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePerPage">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries
              </label>
            </div> -->
			<div class="table-responsive">
              <a-table
                class="stripped table-hover"
                :columns="originalColumn"
                :data-source="data2"
              >
                <template #bodyCell="{index, column, record }">
				  <template v-if="column.key === 'Id'">
                    <div>{{index+1}}</div>
                  </template>
				  <template v-if="column.key === 'Date'">
                    <div> {{ formatDate(record?.date) || record?.date }} </div>
                  </template>
				  <template v-if="column.key === 'ClockIn'">
                    <div>{{ record.firstpunch }}</div>
                  </template>
				  <template v-if="column.key === 'ClockOut'">
                    <div>{{ record.lastpunch }}</div>
                  </template>
				  <template v-if="column.key === 'WorkStatus'">
                    <div v-if="record.is_holiday == 1">Holiday</div>
					<div v-else-if="record.is_leave == 1">{{record.leave_type}}</div>
					<div v-else-if="record.isfuture == 1"></div>
					<div v-else-if="record.total_hours <= 0">Absent</div>
					<div v-else-if="record.total_hours >= 5">Full Day</div>
					<div v-else-if="record.total_hours > 0 && record.total_hours < 5">Half Day</div>
					
                  </template>
				  
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
	
	<div
     id="attendance_report_manage_colvis"
     class="modal custom-modal fade"
     role="dialog"
   >
     <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
       <div class="modal-content">
         <div class="modal-header">
           <h5 class="modal-title">Manage Column Visibility</h5>
           <!-- <small>select the column from the default that you want to hide</small> -->
           <button
             type="button"
             class="btn-close"
             data-bs-dismiss="modal"
             aria-label="Close"
             ref="modal_team_information_close"
           >
             <i class="fas fa-times"></i>
           </button>
         </div>
         <div class="modal-body">
           <div class="row">
                 <div class="col-md-6" v-for="(column,key) in columns_" :key="column.key">
                  <div class="input-block mb-3 d-flex">
                    
                    <div class="form-switch" style="margin-right: 5px;">
                      <input
                        v-model="column.visibility"
                        type="checkbox"
                        class="form-check-input"
                        :id="'customSwitch_'+column.key"
                        value="false"
                        :checked="column.visibility"
                      />
                    </div>
                    <label class="col-form-label"> {{column?.title}} </label>
                  </div>
                 </div>
           </div>
         </div>
       </div>
     </div>
   </div>
  <!-- Update leave_report_manage_colvis Modal -->
  
    <!-- /Page Wrapper -->
  </div>
</template>
<script>
var pagination = {total: 0,current: 1,pageSize: 10};
import axios from 'axios';
import { notification } from "ant-design-vue";
import { Form } from "vee-validate";
const columns = [
  {
    title: "#",
    dataIndex: "Id",
	key: "Id",
    sorter: false,
	visibility:true,
  },
  {
    title: "Date",
    dataIndex: "Date",
	key: "Date",
    sorter: false,
	visibility:true,
  },
  {
    title: "Clock In",
    dataIndex: "ClockIn",
	key: "ClockIn",
    sorter: false,
	visibility:true,
  },
  {
    title: "Clock Out",
    dataIndex: "ClockOut",
    key: "ClockOut",
    sorter: false,
	visibility:true,
  },
  {
    title: "Work Status",
    dataIndex: "WorkStatus",
	key: "WorkStatus",
    sorter: false,
	visibility:true,
  },
];
const data = [
  {
    Id: "1",
    Date: "1 Jan 2023",
    ClockIn: "-",
    ClockOut: "-",
    Class: "",
    WorkStatus: "-",
  },
  {
    Id: "2",
    Date: "2 Jan 2023",
    ClockIn: "-",
    ClockOut: "-",
    Class: "",
    WorkStatus: "-",
  },
  {
    Id: "3",
    Date: "3 Jan 2023",
    ClockIn: "-",
    ClockOut: "-",
    Class: "",
    WorkStatus: "-",
  },
  {
    Id: "4",
    Date: "4 Jan 2023",
    ClockIn: "-",
    ClockOut: "Week Off",
    Class: "text-danger text-center",
    WorkStatus: "-",
  },
  {
    Id: "5",
    Date: "5 Jan 2023",
    ClockIn: "-",
    ClockOut: "Week Off",
    Class: "text-danger text-center",
    WorkStatus: "-",
  },
  {
    Id: "6",
    Date: "6 Jan 2023",
    ClockIn: "-",
    ClockOut: "-",
    WorkStatus: "-",
  },
];
export default {
  components: {
    Form,
  },
  data() {
    return {
      title: "Attendance Reports",
      path: "Dashboard",
      text: "Attendance Reports",
	  text1: "Export Excel",
      columns,
      data,
	  pagination: pagination,
	  employee_list: [],
	  select2: null,
	  Month: [
        "-",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      Year: ["-","2024", "2023", "2022", "2021", "2020", "2019"],
	  searchform: {s_employee_id: "",month: "", year:""},
      AttendanceMonth: ["Jan", "Feb", "Mar"],
      AttendanceYear: ["2023", "2023", "2023"],
      employeeName: "",
      isFocused: false,
	  data2: [],
	  columns_:columns,
    };
  },
  name: "attendance-reports",
  methods: {
	clearForm(){
		
		this.searchform = {s_employee_id: "",month: "", year:""};
		this.data2 = [];
	},
	onSearch(){
		if(this.searchform.s_employee_id == ""){
			
			notification.open({
						message: 'Please Select An Employee',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
			return;		
		}
	
		/*
		if(this.searchform.year == ""){
			
			notification.open({
						message: 'Please Select a Year',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
			return;		
		}
		
		if(this.searchform.month == ""){
			
			notification.open({
						message: 'Please Select a Month',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
			return;		
		}*/
		
		
		var params = {
		   params: { s_employee_id: this.searchform.s_employee_id,month:this.searchform.month, year: this.searchform.year }
		};	
		
		this.loadCommonData(params);
		
	},
	loadCommonData(params){
		console.log('common data loading called..');
		
			var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/employeeattendance/adminattendancereports", params)
			.then((response) => {
				
				//this.totaldays = response.data.days;
				if(response.data.monthlyreport.length){
				
					this.data2 = response.data.monthlyreport[0].monthlyreport.dates;
				}else{
					this.data2 = [];
				}
				
				console.log(this.data2);
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	updatePerPage(){
		console.log(this.pagination.pageSize);
		var params = {
          params: { per_page: this.pagination.pageSize }
        };
        this.loadCommonData(params);
		
	},
	loadAllEmployees(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
					
		axios.get("/employees/allemployees", params)
			.then((response) => {
				
			//	console.log(response.data.data.data);
				
				//this.employee_list = response.data.data;
				
				console.log(response.data.data);
				
				for(let i=0;i<response.data.data.length;i++){
					
					console.log(response.data.data[i]);
					
					this.employee_list.push({"id":response.data.data[i].id,"text":response.data.data[i].first_name+" "+response.data.data[i].last_name});
				}
					
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});			
					
	}
  },
  mounted() {
	
	var params = {
       params: { per_page: this.pagination.pageSize }
    };
	//this.loadCommonData(params);
	
	this.loadAllEmployees([]);
  },
  computed:{
    originalColumn()
    {
      return this.columns_.filter(column => column.visibility);
    }
  },
};
</script>
