<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content container-fluid pb-0">
        <breadcrumb :title="title" :path="path" :text="text" />
		<clientprofilehead :clientdata="clientdata" />
		
		<!--
        <div class="card tab-box">
          <div class="row user-tabs">
            <div class="col-lg-12 col-md-12 col-sm-12 line-tabs pt-3 pb-2">
              <ul class="nav nav-tabs nav-tabs-bottom">
                <li class="nav-item col-sm-3">
                  <a class="nav-link active" data-bs-toggle="tab" href="#myprojects"
                    >Projects</a
                  >
                </li>
                <li class="nav-item col-sm-3">
                  <a class="nav-link" data-bs-toggle="tab" href="#tasks">Tasks</a>
                </li>
              </ul>
            </div>
          </div>
        </div> -->
		<!--
        <div class="row">
          <div class="col-lg-12">
            <div class="tab-content profile-tab-content">
              <clientprofiletab />

              <clienttaskstab />
            </div>
          </div>
        </div> -->
      </div>
	  <clientprofilemodal :employeeData="clientdata" @reload-data="loadCommonData" />
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
export default {
  data() {
    return {
      title: "Profile",
      path: "Dashboard",
      text: "Profile",
	  clientdata: [],
    };
  },
  name: "client-profile",
  methods: {
	loadCommonData(){
		console.log('common data loading called..');
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.fullPage ? null : this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/client/"+this?.$route?.params?.user_id, [])
			.then((response) => {
				
				console.log(response.data.data);
				
				this.clientdata = response.data.data;
			
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	}
  },
  mounted() {
	
	
	var params = {
       params: { client_id: this?.$route?.params?.user_id }
    };
	this.loadCommonData([]);
	
  },
};
</script>
